<template>
  <v-card>
    <v-row class="blueBcg">
      <v-col cols="12" class="text pad subTitle">
        Dettaglio Business Services</v-col
      >
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numBSCageG1I1ByMonth)"
          :title="'BS con server in Cage G1-l1'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numRSNoSequenceByMonthBS)"
          :title="'RS senza sequenza'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numRSWithIssueByMonthBS)"
          :title="'RS con Issue'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartPerimeterService
          :chart="this.setDataChartBar(dataCharts.numBSByPerimetroAndMonth)"
          :title="'Business Service Perimetro'"
        ></ChartPerimeterService
      ></v-col>
    </v-row>
    <v-row class="azzBcg">
      <v-col cols="12" class="text pad subTitle">
        Dettaglio Infrastucture Services</v-col
      >
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numISCageG1I1ByMonth)"
          :title="'IS con server in Cage G1-l1'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numRSNoSequenceByMonthIS)"
          :title="'RS senza sequenza'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numRSWithIssueByMonthIS)"
          :title="'RS con Issue'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="6" class="pad"
        ><ChartPerimeterService
          :chart="this.setDataChartBar(dataCharts.numISByPerimetroAndMonth)"
          :title="'Infrastructure Service Conformità'"
        ></ChartPerimeterService
      ></v-col>
    </v-row>
  </v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ChartLineChart from "@/components/chart/ChartLineChart.vue";
import ChartPerimeterService from "@/components/chart/ChartPerimeterService.vue";
export default {
  name: "DataAdminHistoricalDashboardDetails",
  components: {
    ChartLineChart,
    ChartPerimeterService,
  },
  mixins: [connectionMixin],
  props: {
    dataCharts: {
      type: Object,
    },
  },
  data: () => ({
    loading: false,
    chart: [],
  }),
  computed: {},
  mounted() {},
  methods: {
    setDataChartBar(value) {
      let arrayForChartBar = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      let objKeys = Object.keys(object).map((key) => [key, value[key]]);
      for (var k in objKeys) {
        var conforme = 0;
        var nonConforme = 0;
        var conformeConNote = 0;
        var nome = objKeys[k][0];
        var obj = objKeys[k][1];
        for (let [key, value] of Object.entries(obj)) {
          if (key == "SI") {
            if (value) {
              conforme = value;
            } else {
              conforme = 0;
            }
          }
          if (key == "NO") {
            if (value) {
              nonConforme = value;
            } else {
              conforme = 0;
            }
          }
          if (key == "TBD") {
            if (value) {
              conformeConNote = value;
            } else {
              conforme = 0;
            }
          }
        }
        if (nome) {
          arrayForChartBar.push({
            nome: nome ? this.setMonth(nome) : 0,
            conforme: conforme ? conforme.toFixed(0) : 0,
            nonConforme: nonConforme ? nonConforme.toFixed(0) : 0,
            conformeConNote: conformeConNote ? conformeConNote.toFixed(0) : 0,
          });
        }
      }
      return arrayForChartBar;
    },
    setDataChart(value) {
      let arrayForChart = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      for (var k in object) {
        arrayForChart.push({ name: [k], value: object[k] });
      }
      return arrayForChart;
    },
    setMonth(mon) {
      let monthName = "";
      switch (mon) {
        case "1":
          monthName = "GEN";
          break;
        case "2":
          monthName = "FEB";
          break;
        case "3":
          monthName = "MAR";
          break;
        case "4":
          monthName = "APR";
          break;
        case "5":
          monthName = "MAG";
          break;
        case "6":
          monthName = "GIU";
          break;
        case "7":
          monthName = "LUG";
          break;
        case "8":
          monthName = "AGO";
          break;
        case "9":
          monthName = "SET";
          break;
        case "10":
          monthName = "OTT";
          break;
        case "11":
          monthName = "NOV";
          break;
        case "12":
          monthName = "DIC";
          break;
        default:
          "";
      }
      return monthName;
    },
  },
};
</script>

<style scoped>
.pad {
  padding: 1%;
}
.blueBcg {
  background-color: #2196f3;
}
.azzBcg {
  background-color: #007c80;
}
.text {
  color: rgb(0, 0, 0);
  font-size: bold !important;
  padding: 0px 20px;
}
.subTitle {
  font-weight: bold;
  color: white;
  padding-top: 10px !important;
}
</style>
