<template>
  <v-chart
    class="chart"
    :option="option"
    theme="enitheme"
    autoresize
    :loading="barLoading"
    :loadingOptions="barLoadingOptions"
  />
</template>

<script>
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  GridComponent,
  TooltipComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import theme from "@/assets/enitheme.json";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
]);
// registering custom theme
registerTheme("enitheme", theme);

export default {
  name: "ChartAppImpact",
  components: {
    VChart,
  },
  props: {
    chart: {
      default: [],
      Array,
    },
    title: {
      default: "",
      String,
    },
  },
  data() {
    return {
      barLoading: false,
      barLoadingOptions: {
        text: "Caricamento…",
        textColor: "#fff",
        color: "#fdd300",
        maskColor: "rgba(0, 0, 0, 0.7)",
      },
      option: {
        title: {
          text: this.title,
          top: 10,
          left: "center",
          textStyle: { fontWeight: "normal", fontSize: "12" },
        },
        tooltip: {},
        grid: {
          top: "15%",
          left: "3%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "GEN",
            "FEB",
            "MAR",
            "APR",
            "MAG",
            "GIU",
            "LUG",
            "AGO",
            "SET",
            "OTT",
            "NOV",
            "DIC",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
      },
    };
  },
  mounted() {
    this.getSourceChart(this.$route.params.id);
  },
  methods: {
    getSourceChart() {
      var array = [];
      this.chart.forEach((item) => {
        array.push(item.name);
      });
      this.option.yAxis.data = array;
      this.option.series[0].data = this.chart;
      this.barLoading = false;
    },
  },
  watch: {
    option: function (val) {
      console.log("update option");
      return val;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>
