<template>
  <v-card>
    <v-row class="blueBcg">
      <v-col cols="4" class="pad">
        <ChartLineChart
          :chart="this.setDataChart(dataCharts.numBSByMonth)"
          :title="'Business services'"
        ></ChartLineChart>
      </v-col>
      <v-col cols="4" class="pad"
        ><ChartServiceByClass
          :chart="this.setDataChartBarTwo(dataCharts.numBSByClazzAndMonth)"
          :title="'Business services per Classe'"
        ></ChartServiceByClass
      ></v-col>
      <v-col cols="4" class="pad"
        ><ChartComplianceService
          :chart="this.setDataChartBar(dataCharts.numBSByComplianceAndMonth)"
          :title="'Business service Conformità'"
        ></ChartComplianceService
      ></v-col>
    </v-row>
    <v-row class="azzBcg">
      <v-col cols="4" class="pad"
        ><ChartLineChart
          :chart="this.setDataChart(dataCharts.numISByMonth)"
          :title="'Infrastructure Services'"
        ></ChartLineChart
      ></v-col>
      <v-col cols="4" class="pad"
        ><ChartServiceByClass
          :chart="this.setDataChartBarTwo(dataCharts.numISByClazzAndMonth)"
          :title="'Infrastructure Service per Classe'"
        ></ChartServiceByClass>
      </v-col>
      <v-col cols="4" class="pad"
        ><ChartComplianceService
          :chart="this.setDataChartBar(dataCharts.numISByComplianceAndMonth)"
          :title="'Infrastructure Service Conformità'"
        ></ChartComplianceService
      ></v-col>
    </v-row>
  </v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ChartLineChart from "@/components/chart/ChartLineChart.vue";
import ChartComplianceService from "@/components/chart/ChartComplianceService.vue";
import ChartServiceByClass from "@/components/chart/ChartServiceByClass.vue";
export default {
  name: "DataAdminHistoricaDashboard",
  components: {
    ChartLineChart,
    ChartComplianceService,
    ChartServiceByClass,
  },
  mixins: [connectionMixin],
  props: {
    dataCharts: {
      type: Object,
    },
  },
  data: () => ({
    loading: false,
    chart: [
      { name: "VIVA", value: 3 },
      { name: "SOLO LETTURA", value: 5 },
      { name: "IN DIMISSIONE", value: 0 },
      { name: "PREVISTA", value: 3 },
    ],
  }),
  computed: {},
  mounted() {},
  methods: {
    setDataChartBarTwo(value) {
      let setDataChartBarTwo = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let arrayData = [];
      for (var i in result) {
        result[i][1].name = result[i][0];
        arrayData.push(result[i][1]);
      }
      for (var k in arrayData) {
        let obj = arrayData[k];
        obj.name = this.setMonth(obj.name);
        setDataChartBarTwo.push(obj);
      }
      return setDataChartBarTwo;
    },
    setDataChart(value) {
      let arrayForChart = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      for (var k in object) {
        arrayForChart.push({ name: [k], value: object[k] });
      }
      return arrayForChart;
    },
    setDataChartBar(value) {
      let arrayForChartBar = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      let objKeys = Object.keys(object).map((key) => [key, value[key]]);
      for (var k in objKeys) {
        var conforme = 0;
        var nonConforme = 0;
        var conformeConNote = 0;
        var fuoriPerimetro = 0;
        var nome = objKeys[k][0];
        var obj = objKeys[k][1];
        for (let [key, value] of Object.entries(obj)) {
          if (key == "CONFORME") {
            if (value) {
              conforme = value;
            } else {
              conforme = 0;
            }
          }
          if (key == "NON CONFORME") {
            if (value) {
              nonConforme = value;
            } else {
              conforme = 0;
            }
          }
          if (key == "CONFORME CON NOTE") {
            if (value) {
              conformeConNote = value;
            } else {
              conforme = 0;
            }
          }
          if (key == "FUORI PERIMETRO") {
            if (value) {
              fuoriPerimetro = value;
            } else {
              conforme = 0;
            }
          }
        }
        if (nome) {
          arrayForChartBar.push({
            nome: this.setMonth(nome),
            conforme: conforme.toFixed(0),
            nonConforme: nonConforme.toFixed(0),
            conformeConNote: conformeConNote.toFixed(0),
            fuoriPerimetro: fuoriPerimetro.toFixed(0),
          });
        }
      }
      return arrayForChartBar;
    },
    setMonth(mon) {
      let monthName = "";
      switch (mon) {
        case "1":
          monthName = "GEN";
          break;
        case "2":
          monthName = "FEB";
          break;
        case "3":
          monthName = "MAR";
          break;
        case "4":
          monthName = "APR";
          break;
        case "5":
          monthName = "MAG";
          break;
        case "6":
          monthName = "GIU";
          break;
        case "7":
          monthName = "LUG";
          break;
        case "8":
          monthName = "AGO";
          break;
        case "9":
          monthName = "SET";
          break;
        case "10":
          monthName = "OTT";
          break;
        case "11":
          monthName = "NOV";
          break;
        case "12":
          monthName = "DIC";
          break;
        default:
          "";
      }
      return monthName;
    },
  },
};
</script>

<style scoped>
.pad {
  padding: 1%;
}
.blueBcg {
  background-color: #2196f3;
}
.azzBcg {
  background-color: #007c80;
}
</style>
