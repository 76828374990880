<template>
  <v-card>
    <!-- todo : remove v-if when 151 in prod -->
    <v-row
      v-if="
        dataCharts.globalData.numServicesByComplianceDDBS &&
        dataCharts.globalData.numServicesByComplianceDDIS
      "
    >
      <v-col cols="4" class="pad">
        <ChartCatAppState
          :chart="
            this.setDataChart(dataCharts.globalData.numServicesByStatusIS)
          "
          :title="'Stato App Infrastructure Service'"
        ></ChartCatAppState
      ></v-col>
      <v-col cols="8" class="pad">
        <ChartConfServAreaDD
          :chart="
            this.setDataChartBar(
              dataCharts.globalData.numServicesByComplianceDDBS
            )
          "
          :title="'Conformità Infrastructure Service per area D&D'"
        ></ChartConfServAreaDD>
      </v-col>
      <v-col cols="4" class="pad">
        <ChartCatAppState
          :chart="
            this.setDataChart(dataCharts.globalData.numServicesByStatusBS)
          "
          :title="'Stato App Cat Business Service'"
        ></ChartCatAppState
      ></v-col>
      <v-col cols="8" class="pad">
        <ChartConfServAreaDD
          :chart="
            this.setDataChartBar(
              dataCharts.globalData.numServicesByComplianceDDIS
            )
          "
          :title="'Conformità Business Service per area D&D'"
        ></ChartConfServAreaDD>
      </v-col>
    </v-row>
    <!-- todo : remove row when 151 in prod -->
    <v-row
      v-if="
        dataCharts.globalData.numServicesByComplianceDD &&
        !dataCharts.globalData.numServicesByComplianceDDBS
      "
    >
      <v-col cols="3" class="pad">
        <ChartCatAppState
          :chart="
            this.setDataChart(dataCharts.globalData.numServicesByStatusIS)
          "
          :title="'Stato App Infrastructure Service'"
        ></ChartCatAppState
      ></v-col>
      <v-col cols="3" class="pad">
        <ChartCatAppState
          :chart="
            this.setDataChart(dataCharts.globalData.numServicesByStatusBS)
          "
          :title="'Stato App Cat Business Service'"
        ></ChartCatAppState
      ></v-col>
      <v-col cols="6" class="pad">
        <ChartConfServAreaDD
          :chart="
            this.setDataChartBar(
              dataCharts.globalData.numServicesByComplianceDD
            )
          "
          :title="'Conformità Business Service per area D&D'"
        ></ChartConfServAreaDD>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pad">
        <div>
          <div>
            <p class="subTitle">Location Servizi e Server</p>
            <v-row>
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">{{ dataCharts.globalData.servicesGDC }}</p>
                  <p class="textBox">#Servizi location GDC</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">
                    {{ dataCharts.globalData.servicesNullLocation }}
                  </p>
                  <p class="textBox">#Servizi location NULL</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">
                    {{ dataCharts.globalData.servicesOtherLocation }}
                  </p>
                  <p class="textBox">#Servizi altre location</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">
                    {{ dataCharts.globalData.nodesNoLocation }}
                  </p>
                  <p class="textBox">#Server senza location</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">
                    {{ dataCharts.globalData.servicesNoGDCWithGDCNodes }}
                  </p>
                  <p class="textBox">#Servizio no GDC con server GDC</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxSS">
                  <p class="number">
                    {{ dataCharts.globalData.servicesGDCWithNoGDCNodes }}
                  </p>
                  <p class="textBox">#Servizio GDC con server no GDC</p>
                </div></v-col
              >
            </v-row>
          </div>
          <div>
            <p class="subTitle" style="margin-top: 10px">Anomalie</p>
            <v-row>
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesFPToTest }}
                  </p>
                  <p class="textBox">#Servizi fuori perimetro da testare</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesIPNotToTest }}
                  </p>
                  <p class="textBox">#Servizi in perimetro da non testare</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesIPExcludeScheda }}
                  </p>
                  <p class="textBox">
                    #Servizi in perimetro scheda da escludere
                  </p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesFPIncludeScheda }}
                  </p>
                  <p class="textBox">
                    #Servizi fuori perimetro scheda da includere
                  </p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesEOFNoNodes }}
                  </p>
                  <p class="textBox">#Servizi EOF non nodi non dismessi</p>
                </div></v-col
              >
              <v-col cols="2" style="padding: 0px 10px"
                ><div class="boxAn">
                  <p class="number">
                    {{ dataCharts.globalData.servicesEOFDismessi }}
                  </p>
                  <p class="textBox">#Servizi EOF dismessi</p>
                </div></v-col
              >
            </v-row>
          </div>
        </div>
      </v-col>

      <!-- todo : remove col when 151 in prod -->
      <v-col
        cols="12"
        class="pad"
        v-if="dataCharts.globalData.numServicesByComplianceDDAndClazz"
      >
        <ChartConfServAreaDDClazz
          :chart="
            this.setDataChartBarTwo(
              dataCharts.globalData.numServicesByComplianceDDAndClazz
            )
          "
          :title="'Infrastructure Service per area D&D e classe'"
        ></ChartConfServAreaDDClazz>
      </v-col>
      <!-- todo : remove v-if when 151 in prod -->
      <v-col
        cols="12"
        class="pad"
        v-if="dataCharts.globalData.numServicesByComplianceDDAndClazzBS"
      >
        <ChartConfServAreaDDClazz
          :chart="
            this.setDataChartBarTwo(
              dataCharts.globalData.numServicesByComplianceDDAndClazzBS
            )
          "
          :title="'Infrastructure Service per area D&D e classe'"
        ></ChartConfServAreaDDClazz>
      </v-col>
      <!-- todo : remove v-if when 151 in prod -->
      <v-col
        cols="12"
        class="pad"
        v-if="dataCharts.globalData.numServicesByComplianceDDAndClazzIS"
      >
        <ChartConfServAreaDDClazz
          :chart="
            this.setDataChartBarTwo(
              dataCharts.globalData.numServicesByComplianceDDAndClazzIS
            )
          "
          :title="'Business Service per area D&D e classe'"
        ></ChartConfServAreaDDClazz>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pad"
        ><template>
          <v-data-table
            :headers="headersNode"
            :items="
              setDataTable(
                dataCharts.globalData.numNodesByComplianceBS,
                dataCharts.globalData.numNodesByComplianceIS
              )
            "
            :items-per-page="-1"
            calculate-widths
            class="elevation-1"
          ></v-data-table> </template
      ></v-col>
      <v-col cols="6" class="pad"
        ><template>
          <v-data-table
            :headers="headersRs"
            :items="
              setDataTable(
                dataCharts.globalData.numRSByComplianceBS,
                dataCharts.globalData.numRSByComplianceIS
              )
            "
            :items-per-page="-1"
            calculate-widths
            class="elevation-1"
          ></v-data-table> </template
      ></v-col>
    </v-row>
  </v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ChartCatAppState from "@/components/chart/ChartCatAppState.vue";
import ChartConfServAreaDD from "@/components/chart/ChartConfServAreaDD.vue";
import ChartConfServAreaDDClazz from "@/components/chart/ChartConfServAreaDDClazz.vue";
export default {
  name: "DataAdminDashboard",
  components: {
    ChartCatAppState,
    ChartConfServAreaDD,
    ChartConfServAreaDDClazz,
  },
  mixins: [connectionMixin],
  props: {
    dataCharts: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,

    chart: [
      { name: "VIVA", value: 3 },
      { name: "SOLO LETTURA", value: 5 },
      { name: "IN DIMISSIONE", value: 3 },
      { name: "PREVISTA", value: 3 },
    ],
    headersNode: [
      {
        text: "#BS",
        value: "bs",
      },
      {
        text: "#IS",
        value: "is",
      },
      { text: "NODE CHECK COMPLIANCE", value: "node" },
    ],
    headersRs: [
      {
        text: "#BS",
        value: "bs",
      },
      {
        text: "#IS",
        value: "is",
      },
      { text: "RS CHECK COMPLIANCE", value: "node" },
    ],
  }),
  computed: {},
  mounted() {},
  methods: {
    setDataTable(chartOne, chartTwo) {
      let datatable = [];
      for (let [key, value] of Object.entries(chartOne)) {
        datatable.push({
          bs: value,
          is: 0,
          node: key,
        });
      }
      for (let [keyTwo, valueTwo] of Object.entries(chartTwo)) {
        for (let i of datatable) {
          if (i.node === keyTwo) {
            i.is = valueTwo;
          }
        }
      }
      for (let [keyTwo, valueTwo] of Object.entries(chartTwo)) {
        let result = datatable.find(({ node }) => node === keyTwo);
        if (result) {
          valueTwo;
        }
      }
      return datatable;
    },
    setSecondXLine(value) {
      let secondLineArray = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      let resultData = Object.keys(object).map((key) => [key, value[key]]);
      for (var k in resultData) {
        secondLineArray.push(resultData[k][0]);
      }
      return secondLineArray;
    },
    setDataChartBarTwo(value) {
      let setDataChartBarTwo = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let arrayData = [];

      for (var i in result) {
        result[i][1].name = result[i][0];
        arrayData.push(result[i][1]);
      }
      for (var k in arrayData) {
        var obj = arrayData[k];

        for (let [keyOb, valueOb] of Object.entries(obj)) {
          var conforme = null;
          var nonConforme = null;
          var conformeConNote = null;
          var fuoriPerimetro = null;
          var nome = null;
          if (keyOb !== "name" && keyOb !== "" && obj.name != "-") {
            nome = keyOb + "-" + obj.name;
          }
          for (let [key, value] of Object.entries(valueOb)) {
            if (key == "CONFORME") {
              conforme = value;
            }
            if (key == "NON CONFORME") {
              nonConforme = value;
            }
            if (key == "CONFORME CON NOTE") {
              conformeConNote = value;
            }
            if (key == "FUORI PERIMETRO") {
              fuoriPerimetro = value;
            }
          }
          if (nome !== "" && nome !== "--" && nome !== "-" && nome) {
            setDataChartBarTwo.push({
              nome: nome,
              conforme: conforme,
              nonConforme: nonConforme,
              conformeConNote: conformeConNote,
              fuoriPerimetro: fuoriPerimetro,
            });
          }
        }
      }

      return setDataChartBarTwo;
    },
    setDataChart(value) {
      let arrayForChart = [];
      if (value != "" && value != null) {
        let result = Object.keys(value).map((key) => [key, value[key]]);
        let object = result.reduce(
          (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
          {}
        );
        for (var k in object) {
          if (k != "") {
            arrayForChart.push({ name: [k], value: object[k] });
          }
        }
      }

      return arrayForChart;
    },
    setDataChartBar(value) {
      let arrayForChartBar = [];
      let result = Object.keys(value).map((key) => [key, value[key]]);
      let object = result.reduce(
        (obj, item) => Object.assign(obj, { [item[0]]: item[1] }),
        {}
      );
      let objKeys = Object.keys(object).map((key) => [key, value[key]]);
      for (var k in objKeys) {
        var conforme = null;
        var nonConforme = null;
        var conformeConNote = null;
        var fuoriPerimetro = null;
        var nome = objKeys[k][0];
        var obj = objKeys[k][1];
        for (let [key, value] of Object.entries(obj)) {
          if (key == "CONFORME") {
            conforme = value;
          }
          if (key == "NON CONFORME") {
            nonConforme = value;
          }
          if (key == "CONFORME CON NOTE") {
            conformeConNote = value;
          }
          if (key == "FUORI PERIMETRO") {
            fuoriPerimetro = value;
          }
        }
        if (nome != "-" && nome != "") {
          arrayForChartBar.push({
            nome: nome,
            conforme: conforme,
            nonConforme: nonConforme,
            conformeConNote: conformeConNote,
            fuoriPerimetro: fuoriPerimetro,
          });
        }
      }
      return arrayForChartBar;
    },
  },
};
</script>

<style scoped>
.box {
  border: 1px solid;
  padding: 2px;
}
.pad {
  padding: 1%;
}
.boxSS {
  text-align: center;
  padding: 10px;
  background-color: #ebdaeb;
  min-height: 90px;
}
.boxAn {
  text-align: center;
  padding: 10px;
  background-color: #f18022;
  min-height: 90px;
}
p.number {
  font-size: xx-large;
  font-weight: bold;
  font-size: 20px;
}
p.textBox {
  font-size: 12px;
}
.subTitle {
  font-weight: bold;
  padding: 5px;
}
</style>
