<template>
  <v-card>
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-data-table
      dense
      v-model="selected"
      :show-select="edit"
      multi-sort
      fixed-header
      height="calc(100vh - 450px)"
      loading-text="Caricamento dati... Attendere, per favore."
      class="main-table elevation-1 mt-0"
      item-key="name"
      @update:items-per-page="updatePagination($event)"
      @toggle-select-all="toggleSelectAll()"
      :headers="headerTableAdmin"
      :items="adminData"
      :calculate-widths="false"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      v-if="selected.length == adminData.length"
                      class="bkgrdcolor2"
                    >
                      Stai modificando <u>TUTTI</u> gli elementi</v-col
                    >
                    <v-col
                      cols="12"
                      v-if="
                        selected.length > 1 &&
                        selected.length < adminData.length
                      "
                      class="bkgrdcolor2"
                    >
                      Stai modificando più elementi</v-col
                    >
                    <v-col
                      cols="12"
                      v-if="selected.length == 1"
                      class="bkgrdcolor"
                    >
                      {{ editedItem.name }}</v-col
                    >
                    <v-col cols="6">
                      <v-select
                        v-model="editedItem.perimetroScheda"
                        :items="['SI', 'NO', 'TBD']"
                        label="Perimetro Scheda"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.perimetroSchedaNote"
                        label="Perimetro Scheda Note"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="10">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateFormatted"
                                label="Data Condivisione Scheda"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                readonly
                                @blur="date = parseDate(dateFormatted)"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              no-title
                              @input="menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2" style="padding-top: 30px">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                rounded
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="clearData()"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Cancella data</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="editedItem.perimetroSchedaTest"
                        :items="['SI', 'NO', 'TBD']"
                        label="Perimetro Test"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>

                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.perimetroSchedaTestNote"
                        label="Perimetro Test Note"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn
                  color="black darken-1"
                  text
                  @click="save"
                  :disabled="itemChanged"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`body.prepend`]>
        <tr class="primary lighten-4 dt-topologia-search-column">
          <td></td>
          <td></td>
          <td>
            <v-text-field
              v-model="ccameSrc"
              type="text"
              label="ccame"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="nomeSrc"
              type="text"
              label="nome"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="statoSrc"
              type="text"
              label="stato"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="tipoSrc"
              type="text"
              label="tipo"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="areaDettaglioSrc"
              type="text"
              label="area dettaglio"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="compliance"
              type="text"
              label="Conformità Originale (uCMDB)"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="calculatedCompliance"
              type="text"
              label="Conformità Semplificata (Virgilio)"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="classeSrc"
              type="text"
              label="classe"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="disponibilitaSrc"
              type="text"
              label="disponibilità"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="location"
              type="text"
              label="Location"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="serverGDCSrc"
              type="text"
              label="Server GDC"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>

          <td>
            <v-text-field
              v-model="serverGDCStrechedSrc"
              type="text"
              label="Server GDC streched"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>

          <td>
            <v-text-field
              v-model="fullStretchedC2"
              type="text"
              label="C2 full streched"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="perimetroSchedaSrc"
              type="text"
              label="perimetro scheda"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="perimetroSchedaNoteSrc"
              type="text"
              label="perimetro scheda note"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="dataCondivisione"
              type="text"
              label="data condivisione scheda"
              color="grey lighten-2"
            >
            </v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="perimetroSrc"
              type="text"
              label="perimetro test"
              color="grey lighten-2"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="perimetroNoteSrc"
              type="text"
              label="perimetro test note"
              color="grey lighten-2"
            ></v-text-field>
          </td>
        </tr>
      </template>
      <template v-slot:[`header.actions`]="{ header }">
        <span>Action</span>
        <v-btn
          :disabled="selected.length == 0"
          class="mr-2 actionBtn"
          @click="editItem()"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >{{ header.text }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="mr-2 actionBtn" @click="editItem(item)"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  name: "DataTableTopologiaRs",
  mixins: [connectionMixin],
  props: {
    edit: {
      default: false,
      Boolean,
    },
  },
  data: () => ({
    loading: false,
    adminData: [],
    selected: [],
    header: [],
    dialog: false,
    editedIndex: -1,
    editedItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },

    defaultItem: {
      text: "",
      align: "",
      value: "",
      sortable: true,
      class: "",
    },
    itemsPerPage: 10,
    texAreaError: false,
    texAreaErrorTwo: false,
    itemChanged: true,
    updateDataValue: "",
    ccameSrc: "",
    nomeSrc: "",
    statoSrc: "",
    tipoSrc: "",
    areaDettaglioSrc: "",
    classeSrc: "",
    disponibilitaSrc: "",
    serverGDCSrc: "",
    serverGDCStrechedSrc: "",
    location: "",
    compliance: "",
    calculatedCompliance: "",
    fullStrachedSrc: "",
    perimetroSchedaSrc: "",
    fullStretchedC2: "",
    perimetroSchedaNoteSrc: "",
    dataCondivisione: "",
    perimetroSrc: "",
    perimetroNoteSrc: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    dateFormatted: "",
    menu1: false,
    displayProgress: false,
  }),
  computed: {
    headerTableAdmin() {
      this.setHeader();
      return this.header;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.displayProgress = true;
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_ADMIN"),
          this.opts
        );
        if (response) {
          this.adminData = response.data.services;
          this.updateDataValue = response.data.lastUpdate;
          this.adminData.forEach((el) => {
            el.dataCondivisione = this.formatDateTable(el.dataCondivisione);
          });
          this.loading = false;
          this.displayProgress = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        alert("Si prega di ricaricare la pagina");
      }

      this.$emit("updateData", this.updateDataValue);
    },
    setHeader() {
      this.header = [
        {
          text: "CCAME",
          align: "center",
          value: "came",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["ccameSrc"].toLowerCase());
          },
        },
        {
          text: "Nome",
          align: "center",
          value: "name",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nomeSrc"].toLowerCase());
          },
        },

        {
          text: "Stato",
          align: "center",
          value: "stato",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          cellClass: "cellFont",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["statoSrc"].toLowerCase());
          },
        },
        {
          text: "Tipo",
          align: "center",
          value: "type",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["tipoSrc"].toLowerCase());
          },
        },
        {
          text: "Area Dettaglio",
          align: "center",
          value: "areaDd",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["areaDettaglioSrc"].toLowerCase());
          },
        },
        {
          text: "Conformità Originale (uCMDB)",
          align: "center",
          value: "compliance",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["compliance"].toLowerCase());
          },
        },
        {
          text: "Conformità Semplificata (Virgilio)",
          align: "center",
          value: "calculatedCompliance",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["calculatedCompliance"].toLowerCase());
          },
        },
        {
          text: "Classe",
          align: "center",
          value: "clazz",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["classeSrc"].toLowerCase());
          },
        },
        {
          text: "Disponibilità",
          align: "center",
          value: "disponibilita",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["disponibilitaSrc"].toLowerCase());
          },
        },
        {
          text: "Location",
          align: "center",
          value: "location",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["location"].toLowerCase());
          },
        },
        {
          text: "#Server GDC",
          align: "center",
          value: "numServerGDC",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["serverGDCSrc"].toLowerCase());
          },
        },

        {
          text: "#Server GDC Streched",
          align: "center",
          value: "numServerGDCStretched",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["serverGDCStrechedSrc"].toLowerCase());
          },
        },
        {
          text: "C2 Full Streched",
          align: "center",
          value: "fullStretchedC2",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["fullStretchedC2"].toLowerCase());
          },
        },
        {
          text: "Perimetro Scheda",
          align: "center",
          value: "perimetroScheda",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["perimetroSchedaSrc"].toLowerCase());
          },
        },
        {
          text: "Perimetro Scheda Note",
          align: "center",
          value: "perimetroSchedaNote",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["perimetroSchedaNoteSrc"].toLowerCase());
          },
        },
        {
          text: "Data condivisione scheda",
          align: "center",
          value: "dataCondivisione",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["dataCondivisione"].toLowerCase());
          },
        },
        {
          text: "Perimetro test",
          align: "center",
          value: "perimetroSchedaTest",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["perimetroSrc"].toLowerCase());
          },
        },
        {
          text: "Perimetro test note",
          align: "center",
          value: "perimetroSchedaTestNote",
          sortable: true,
          class: "primary lighten-4 white--text text-top",
          width: 100,
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["perimetroNoteSrc"].toLowerCase());
          },
        },
      ];
      if (this.edit) {
        this.header.unshift({
          align: "center",
          value: "actions",
          sortable: false,
          class: "primary lighten-4 white--text text-top",
          icon: "mdi-pencil",
          width: 50,
        });
      }
    },
    editItem(item) {
      this.itemChanged = true;
      if (item) {
        if (this.selected.indexOf(item) == -1) {
          this.selected.push(item);
        }
        this.editedIndex = this.adminData.indexOf(item);
        this.dateFormatted = item.dataCondivisione;
        this.editedItem = Object.assign({}, item);
      } else {
        this.adminData.forEach((el) => {
          if (this.selected.indexOf(el) == -1) {
            this.selected.push(el);
          }
          this.editedIndex = this.adminData.indexOf(el);
          this.editedItem = Object.assign({}, el);
        });
      }
      this.dialog = true;
    },
    itemRowBackground: function (item) {
      var index = this.adminData.indexOf(item);
      return this.adminData[index].edit == true ? "backgroundColor" : "";
    },
    toggleSelectAll() {
      if (this.selected.length != 0) {
        this.selected = [];
      }
    },
    checkItem() {
      if (this.editedIndex == -1) {
        this.editedIndex = 0;
      }
      if (
        this.adminData[this.editedIndex].perimetroScheda ==
          this.editedItem.perimetroScheda &&
        this.adminData[this.editedIndex].perimetroSchedaNote ==
          this.editedItem.perimetroSchedaNote &&
        this.adminData[this.editedIndex].dataCondivisione ==
          this.editedItem.dataCondivisione &&
        this.adminData[this.editedIndex].perimetroSchedaTest ==
          this.editedItem.perimetroSchedaTest &&
        this.adminData[this.editedIndex].perimetroSchedaTestNote ==
          this.editedItem.perimetroSchedaTestNote
      ) {
        this.itemChanged = true;
      } else {
        this.itemChanged = false;
      }
    },
    save() {
      if (this.editedIndex > -1) {
        this.selected.forEach((el) => {
          if (el.keys.schedaUcmdbId !== this.editedItem.keys.schedaUcmdbId) {
            if (
              this.adminData[this.editedIndex].perimetroScheda !==
              this.editedItem.perimetroScheda
            ) {
              el.perimetroScheda = this.editedItem.perimetroScheda;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaNote !==
              this.editedItem.perimetroSchedaNote
            ) {
              el.perimetroSchedaNote = this.editedItem.perimetroSchedaNote;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].dataCondivisione !==
              this.editedItem.dataCondivisione
            ) {
              el.dataCondivisione = this.editedItem.dataCondivisione;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaTest !==
              this.editedItem.perimetroSchedaTest
            ) {
              el.perimetroSchedaTest = this.editedItem.perimetroSchedaTest;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaTestNote !==
              this.editedItem.perimetroSchedaTestNote
            ) {
              el.perimetroSchedaTestNote =
                this.editedItem.perimetroSchedaTestNote;
              el.edit = true;
            }
          }
        });
        this.selected.forEach((el) => {
          if (el.keys.schedaUcmdbId == this.editedItem.keys.schedaUcmdbId) {
            if (
              this.adminData[this.editedIndex].perimetroScheda !==
              this.editedItem.perimetroScheda
            ) {
              el.perimetroScheda = this.editedItem.perimetroScheda;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaNote !==
              this.editedItem.perimetroSchedaNote
            ) {
              el.perimetroSchedaNote = this.editedItem.perimetroSchedaNote;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].dataCondivisione !==
              this.editedItem.dataCondivisione
            ) {
              el.dataCondivisione = this.editedItem.dataCondivisione;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaTest !==
              this.editedItem.perimetroSchedaTest
            ) {
              el.perimetroSchedaTest = this.editedItem.perimetroSchedaTest;
              el.edit = true;
            }
            if (
              this.adminData[this.editedIndex].perimetroSchedaTestNote !==
              this.editedItem.perimetroSchedaTestNote
            ) {
              el.perimetroSchedaTestNote =
                this.editedItem.perimetroSchedaTestNote;
              el.edit = true;
            }
          }
        });
        this.$emit("modifiedAdmin", this.adminData);
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    checktextArea(input) {
      this.texAreaError = true;
      if (input && input.length > 4000) {
        this.texAreaError = false;
      } else {
        this.texAreaError = true;
      }
      return this.texAreaError;
    },
    checktextAreaTwo(input) {
      this.texAreaErrorTwo = false;
      if (input && input.length > 4000) {
        this.texAreaErrorTwo = false;
      } else {
        this.texAreaErrorTwo = true;
      }
      return this.texAreaErrorTwo;
    },
    updatePagination(ev) {
      this.itemsPerPage = ev;
    },
    clearData() {
      this.dateFormatted = "";
      this.editedItem.dataCondivisione = "";
      this.checkItem();
    },
    formatDateTable(date) {
      if (!date) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    formatDate(date) {
      if (!date) {
        this.editedItem.dataCondivisione = "";
        this.checkItem();
        return null;
      }

      const [year, month, day] = date.split("-");
      this.editedItem.dataCondivisione = `${day}/${month}/${year}`;
      this.checkItem();
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) {
        this.checkItem();
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
};
</script>

<style scoped>
.headerFont {
  font-size: 20px;
}
.v-data-table >>> tbody td {
  font-size: 12px !important;
}
.main-table table {
  table-layout: fixed;
}
.v-input.searchStyle.v-input--hide-details.theme--light.v-text-field.v-text-field--single-line.v-text-field--is-booted {
  max-width: 20%;
}
.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.theme--light.v-input--selection-controls.v-input--checkbox.primary--text {
  padding: 0px 15px;
}
.search-row-dttopologiars {
  margin-top: -24px;
}

.search-row-dttopologiars .v-btn {
  height: 26px;
  min-width: 36px;
  padding: 0 0px;
  margin-top: 15px;
}

.search-row-dttopologiars .v-btn .v-icon {
  margin-right: 0px !important;
}
.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  padding-left: 10px;
}
th.text-start {
  background-color: #949494 !important;
}
.actionBtn {
  height: 0px !important;
  min-width: 0px !important;
  padding: 0 !important;
}
.bkgrdcolor.col.col-12 {
  background-color: #fdd300;
  color: black;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.bkgrdcolor2.col.col-12 {
  color: red;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.v-data-table >>> .v-data-table__wrapper {
  height: calc(100vh - 375px) !important;
}
</style>
