<template>
  <v-sheet elevation="1">
    <v-chart
      class="chart"
      :option="option"
      theme="enitheme"
      :manual-update="false"
      autoresize
      :loading="barLoading"
      :loadingOptions="barLoadingOptions"
    />
  </v-sheet>
</template>

<script>
import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import theme from "@/assets/enitheme.json";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
]);
// registering custom theme
registerTheme("enitheme", theme);

export default {
  name: "ChartConfServAreaDDClazz",
  components: {
    VChart,
  },
  props: {
    chart: {
      type: Array,
      default: function () {
        return [];
      },
    },
    secondLine: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: {
      default: "",
      String,
    },
  },
  data() {
    return {
      barLoading: false,
      barLoadingOptions: {
        text: "Caricamento…",
        textColor: "#fff",
        color: "#fdd300",
        maskColor: "rgba(0, 0, 0, 0.7)",
      },

      option: {
        title: {
          text: this.title,
          top: 10,
          left: "center",
          textStyle: { fontWeight: "normal", fontSize: "12" },
        },
        tooltip: {},
        legend: {
          data: [
            "CONFORME NOTE",
            "CONFORME",
            "FUORI PERIMETRO",
            "NON CONFORME",
          ],
          top: 50,
          right: 20,
        },
        grid: {
          top: "25%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataset: {
          dimensions: [
            "nome",
            "conformeConNote",
            "conforme",
            "fuoriPerimetro",
            "nonConforme",
          ],
          source: [],
        },
        xAxis: [
          {
            type: "category",
            axisLabel: { rotate: 30, interval: 0 },
            yAxisIndex: 2,
          },
          {
            position: "bottom",
            data: this.secondLine,
            interval: 1,
            axisLine: {
              show: false,
            },
            axisTick: {
              alignWithLabel: false,
              length: 40,
              align: "left",
              interval: function (index, value) {
                return value ? true : false;
              },
            },
            axisLabel: {
              margin: 30,
            },
            splitLine: {
              show: true,
              interval: function (index, value) {
                return value ? true : false;
              },
            },
          },
        ],
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            name: "CONFORME NOTE",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "CONFORME",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },

          {
            name: "FUORI PERIMETRO",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
          {
            name: "NON CONFORME",
            type: "bar",
            label: {
              show: true,
              position: "inside",
              align: "center",
              verticalAlign: "middle",
            },
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getOption();
    }, 500);
  },

  methods: {
    getOption() {
      this.option.dataset.source = this.chart;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 350px;
}
</style>
