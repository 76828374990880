<template>
  <div id="tabsDetails">
    <v-tabs
      v-model="tab"
      background-color="secondary darken-1"
      centered
      @change="changeTab(tab)"
    >
      <v-tab href="#tab-1">DASHBOARD</v-tab>
      <v-tab href="#tab-2">DASHBOARD STORICO</v-tab>
      <v-tab href="#tab-3">DASHBOARD STORICO DETTAGLI</v-tab>
      <v-tab href="#tab-4" v-if="userProfile !== 'readonly'"
        >ADMIN INFORMAZIONI SERVIZI</v-tab
      >

      <v-tabs-slider color="accent"></v-tabs-slider>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1" class="secondary darken-1 pt-2">
        <DataAdminDashboard
          v-if="dataCharts"
          :dataCharts="dataCharts"
        ></DataAdminDashboard>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-2" class="secondary darken-1 pt-2">
        <DataAdminHistoricaDashboard
          v-if="dataCharts"
          :dataCharts="dataCharts"
        ></DataAdminHistoricaDashboard>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-3" class="secondary darken-1 pt-2">
        <DataAdminHistoricalDashboardDetails
          v-if="dataCharts"
          :dataCharts="dataCharts"
        ></DataAdminHistoricalDashboardDetails>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-4" class="secondary darken-1 pt-2">
        <DataTableAdminService
          v-if="!reset"
          @modifiedAdmin="sendToParentFromTableAdmin"
          @updateData="sendToParentUpdateData"
          :edit="edit"
        ></DataTableAdminService>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DataTableAdminService from "./DataTableAdminService.vue";
import DataAdminDashboard from "./DataAdminDashboard.vue";
import DataAdminHistoricaDashboard from "./DataAdminHistoricaDashboard.vue";
import DataAdminHistoricalDashboardDetails from "./DataAdminHistoricalDashboardDetails.vue";
import connectionMixin from "@/mixins/connectionParams.js";
import user from "@/mixins/profileUser.js";

export default {
  mixins: [connectionMixin, user],
  components: {
    DataTableAdminService,
    DataAdminDashboard,
    DataAdminHistoricaDashboard,
    DataAdminHistoricalDashboardDetails,
  },
  props: {
    tabSelect: {
      default: "",
      String,
    },
    reset: {
      default: false,
      Boolean,
    },
    edit: {
      default: false,
      Boolean,
    },
  },
  data() {
    return {
      tab: null,
      tablesChanges: [],
      dataCharts: null,
      updateDataValue: "",
      userProfile: "",
    };
  },
  mounted() {
    if (this.tabSelect) {
      this.tab = this.tabSelect;
    }
    this.getUserProfile();
    this.getData();
  },

  methods: {
    sendToParentUpdateData(value) {
      this.updateDataValue = value;
      this.$emit("updateData", this.updateDataValue);
    },
    async getData() {
      this.loading = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_DASHBOARD"),
          this.opts
        );
        if (response) {
          this.dataCharts = response.data;
          if (response.data.lastUpdate) {
            this.sendToParentUpdateData(response.data.lastUpdate);
          }
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        alert("Si prega di ricaricare la pagina");
      }
    },
    sendToParentFromTableAdmin(value) {
      value.forEach((el) => {
        if (el.edit == true) {
          var updates = {};
          updates.keys = el.keys;
          updates.perimetroScheda = el.perimetroScheda;
          updates.perimetroSchedaNote = el.perimetroSchedaNote;
          updates.dataCondivisione = el.dataCondivisione;
          updates.perimetroSchedaTest = el.perimetroSchedaTest;
          updates.perimetroSchedaTestNote = el.perimetroSchedaTestNote;
          this.tablesChanges.push(updates);
        }
      });

      this.$emit("modified", this.tablesChanges);
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    changeTab(tab) {
      this.$emit("changeTab", tab);
    },

    clearTableChanges() {
      this.tablesChanges = [];
    },
  },
};
</script>

<style scoped>
div#tab-1 {
  padding-top: 0px !important;
}
.ps {
  height: calc(100vh - 325px);
}
</style>
