var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden",attrs:{"min-height":"calc(100vh - 68px)","color":"accent"}},[_c('v-overlay',{attrs:{"value":_vm.displayProgress}},[_c('v-progress-circular',{attrs:{"size":40,"width":4,"color":"secondary","indeterminate":""}})],1),[_c('b-breadcrumb',{attrs:{"items":_vm.breadCrumbs}})],_c('v-banner',{attrs:{"single-line":"","sticky":"","dark":"","px-6":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('BackActionBar',{on:{"goback":_vm.clickOnBack}})],1),_c('v-col',{staticClass:"d-flex justify-center flex-column text-center",attrs:{"cols":"8"}},[_c('ContextualActionBar',{attrs:{"subtitle":'Dashboard e Perimetro Schede',"title":' '},on:{"goback":_vm.clickOnBack}})],1),(_vm.tab == 'tab-4')?_c('v-col',{staticClass:"pr-3 pl-0 pt-6 text-right",attrs:{"cols":"2"}},[(this.editedTableValue.length == 0)?_c('v-btn',{staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":"","disabled":""},on:{"click":function($event){return _vm.clicOnReset()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.clicOnReset()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3370100386)},[_c('span',[_vm._v("RESET")])]),(this.editedTableValue.length == 0)?_c('v-btn',{staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":"","disabled":""},on:{"click":function($event){return _vm.clicOnSave()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.clicOnSave()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,3699757614)},[_c('span',[_vm._v("SALVA")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1 menuBtn",attrs:{"rounded":"","color":"secondary","dark":""},on:{"click":_vm._exportAdmin}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,3446007784)},[_c('span',[_vm._v("ESPORTA")])])],1):_vm._e()],1),(_vm.successMsg)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" Salvataggio avvenuto con successo. ")]):_vm._e(),(_vm.errorMsg)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Errore durante il salvataggio. ")]):_vm._e(),_c('v-row',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"infoFirstLine"}},[_c('span',{staticClass:"redColor"},[_vm._v(" I grafici, i volumi e le informazioni riportate in questa sezione si riferiscono ESCLUSIVAMENTE ai soli ambienti di PRODUZIONE ")]),_c('span',[_c('span',{staticClass:"secondary--text"},[_vm._v("Data aggiornamento dati: ")]),_c('em',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.updateDataValue))])])])])],1)],1),_c('v-sheet',{staticClass:"overflow-y-auto pt-0",attrs:{"id":"scrolling-techniques-7","max-height":"calc(100vh - 157px)","color":"accent"}},[_c('perfect-scrollbar',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DashboardadminTabs',{ref:"dashboardadminTabs",attrs:{"tabSelect":_vm.tab,"reset":_vm.resetEvent,"edit":!_vm.lockedByOtherUser},on:{"modified":_vm.fromChildTable,"updateData":_vm.fromChild,"changeTab":_vm.tabChange}})],1)],1)],1)],1)],1),_c('ModalConfirm',{attrs:{"dialogConfirm":_vm.openDialogConfirm,"text":_vm.dialogText},on:{"open-dialog-confirm":_vm.updateDialogConfirm,"confirmed":function($event){return _vm.save()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }