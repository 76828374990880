<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb> </template>

    <v-banner single-line sticky dark px-6>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="clickOnBack"></BackActionBar>
        </v-col>
        <v-col cols="8" class="d-flex justify-center flex-column text-center">
          <ContextualActionBar
            :subtitle="'Dashboard e Perimetro Schede'"
            :title="' '"
            @goback="clickOnBack"
          ></ContextualActionBar>
        </v-col>
        <v-col cols="2" class="pr-3 pl-0 pt-6 text-right" v-if="tab == 'tab-4'">
          <v-btn
            v-if="this.editedTableValue.length == 0"
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnReset()"
            disabled
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnReset()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>RESET</span>
          </v-tooltip>
          <v-btn
            v-if="this.editedTableValue.length == 0"
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnSave()"
            disabled
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnSave()"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>SALVA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="_exportAdmin"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-alert type="success" v-if="successMsg">
        Salvataggio avvenuto con successo.
      </v-alert>
      <v-alert type="error" v-if="errorMsg">
        Errore durante il salvataggio.
      </v-alert>
      <v-row class="d-flex flex-row justify-center align-center">
        <v-col cols="12">
          <div id="infoFirstLine">
            <span class="redColor">
              I grafici, i volumi e le informazioni riportate in questa sezione
              si riferiscono ESCLUSIVAMENTE ai soli ambienti di PRODUZIONE
            </span>
            <span>
              <span class="secondary--text">Data aggiornamento dati: </span>
              <em class="font-weight-regular"> {{ updateDataValue }}</em>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-banner>

    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      max-height="calc(100vh - 157px)"
      color="accent"
    >
      <perfect-scrollbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <DashboardadminTabs
                ref="dashboardadminTabs"
                @modified="fromChildTable"
                @updateData="fromChild"
                @changeTab="tabChange"
                :tabSelect="tab"
                :reset="resetEvent"
                :edit="!lockedByOtherUser"
              ></DashboardadminTabs>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="dialogText"
      v-on:confirmed="save()"
    ></ModalConfirm>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import DashboardadminTabs from "@/components/DashboardadminTabs.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import download from "downloadjs";
import BackActionBar from "../components/core/BackActionBar.vue";

export default {
  name: "OperatingCardDetails",
  components: {
    ContextualActionBar,
    DashboardadminTabs,
    ModalConfirm,
    BackActionBar,
  },
  mixins: [connectionMixin],
  data() {
    return {
      lockedByOtherUser: true,
      lockId: "",
      cardData: {},
      displayProgress: false,
      backUrl: "",
      editedTableValue: [],
      updateDataValue: "",
      dialogText: "",
      openDialogConfirm: false,
      resetEvent: false,
      successMsg: false,
      errorMsg: false,
      tab: "tab-1",
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          active: true,
        },
      ],
      breadCrumbs: [
        {
          text: "Home",
          to: "/",
        },

        {
          text: "Dashboard e Perimetro Schede",
          href: "",
        },
      ],
    };
  },
  computed: {},
  beforeDestroy() {
    this.releaseEditLock();
  },
  methods: {
    fromChildTable(value) {
      this.editedTableValue = value;
    },
    fromChild(value) {
      this.updateDataValue = value;
    },
    goback() {
      this.$router.push("/");
    },
    async tabChange(value) {
      if (value === "tab-4") {
        const locked = await this.lockEditResource();
        if (!locked) {
          this.tab = value;
        }
      } else {
        this.releaseEditLock();
        this.tab = value;
      }
    },
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/admin",
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.lockedByOtherUser = false;
        this.displayProgress = false;
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
          this.lockedByOtherUser = true;
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.displayProgress = false;
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    clickOnBack() {
      if (this.editedTableValue.length > 0) {
        this.dialogText = "Tornando indietro perderai le modifiche";
        this.updateDialogConfirm(true);
      } else {
        this.goback();
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    clicOnSave() {
      this.dialogText = "Le modifiche verranno propagate su uCMDB. Confermi?";
      this.updateDialogConfirm(true);
    },
    clicOnReset() {
      this.dialogText = "Vuoi resettare le modifiche?";
      this.updateDialogConfirm(true);
    },
    reset() {
      this.resetEvent = true;
      this.displayProgress = true;
      this.tab = "tab-4";
      setTimeout(() => {
        this.resetEvent = false;
        this.displayProgress = false;
        this.editedTableValue = [];
        this.tab = "tab-4";
      }, 500);
    },
    save() {
      if (
        this.dialogText == "Le modifiche verranno propagate su uCMDB. Confermi?"
      ) {
        this.update();
        this.updateDialogConfirm(false);
      }
      if (this.dialogText == "Vuoi resettare le modifiche?") {
        this.reset();
        this.updateDialogConfirm(false);
      }
      if (this.dialogText == "Tornando indietro perderai le modifiche") {
        this.goback();
        this.updateDialogConfirm(false);
      }
    },
    async update() {
      this.editedTableValue.forEach((el) => {
        if (el.dataCondivisione) {
          const [year, month, day] = el.dataCondivisione.split("/");
          el.dataCondivisione = `${day}-${month}-${year}`;
          el.dataCondivisione = new Date(el.dataCondivisione).toISOString();
        }
      });
      var bodyData = {
        ucmdbPut: false,
        updateSP: false,
        adminServices: this.editedTableValue,
      };
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_CARDS") +
            this.$env.value("API_UPDATE"),
          bodyData,
          this.opts
        );
        this.editedTableValue = [];
        this.$refs.dashboardadminTabs.clearTableChanges();
        this.reset();
        this.displayProgress = false;
        if (response.data.errorCount > 0) {
          this.infoAlerts = response.data.errorMessages;
          this.updateDialogAlert(true);
        } else {
          this.successMsg = true;
          setInterval(() => {
            this.successMsg = false;
          }, 10000);
        }
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        this.errorMsg = true;
        setInterval(() => {
          this.errorMsg = false;
        }, 10000);
      }
    },
    async _exportAdmin() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_ADMIN"),
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, "export-Dashboard-Admin-Schede.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
  },
};
</script>
<style scoped>
.ps {
  height: calc(100vh - 269px);
  width: 100%;
}
#infoFirstLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
}
.redColor {
  color: red;
  font-size: smaller;
}
</style>
